<template>
  <v-container fluid :height="height" :style="{ 'min-height': height + 'px' }">
    <v-row justify="center">
      <v-col cols="12">
        <v-text-field :class="isDarkTheme ? 'text-input-white' : ''" v-model="trackingNumber" variant="outlined"
          density="compact" label="Enter a Tracking Number" type="text" :hint="trackingNumberHint" persistent-hint
          hide-details="auto" @update:modelValue="checkTracker">
          <template v-if="canSaveCurrentTracker" v-slot:append>
            <v-btn size="x-small" @click="saveTrackerInit">Save</v-btn>
          </template>
        </v-text-field>
        <TrackerSaveDialog
          v-model="saveTrackerDialog"
          :tracker="trackingNumber"
          :carrier="courier"
          @saved-tracker-updated="savedTrackerUpdated"
        ></TrackerSaveDialog>
      </v-col>
    </v-row>
    <v-row v-if="showProgressBar">
      <v-col cols="12">
        <v-progress-linear indeterminate rounded height="2"></v-progress-linear>
      </v-col>
    </v-row>
    <template v-if="showTimeline">
      <v-row>
        <v-col cols="12">
          <Splitpanes
            :horizontal="landscapeMode ? undefined : true"
            :style="{ width: '100%', height: (height - 125) + 'px' }"
            class="splitpanes"
            :dbl-click-splitter="false"
            @resized="trackerHistoryMapPanesResized">
            <pane min-size="10" :size="trackingMapPaneSizePercent">
              <GoogleMap ref="trackingMap" :api-key="googleAPIKey" :style="{ width: '100%', height: '100%' }"
                :center="{ lat: 39.8333, lng: -98.585522 }" :zoom="trackingMapZoom" :clickable-icons="false"
                :keyboard-shortcuts="false" :map-type-id="trackingMapOptions.mapType"
                :map-type-control-options="trackingMapOptions.mapTypeOptions" :fullscreen-control="false"
                :street-view-control="false" @zoom_changed="trackingMapZoomChanged"
                :styles="isDarkTheme ? mapDarkTheme.styles : []">
                <Marker
                  v-for='(hop, index) in legHops()'
                  :key="index"
                  :options="getMarkerOptions(hop, index, legHops().length - 1)"
                  ref="trackingMapMarkers"
                />
                <Polyline :options="trackingPath()" />
              </GoogleMap>
            </pane>
            <pane 
              :size="trackingHistoryPaneSizePercent"
              :min-size="trackingHistoryPaneMinSizePercent"
              :max-size="trackingHistoryPaneMaxSizePercent"
              style="position: relative;">
              <div :class="isDarkTheme ? 'tracking-history-table-dark pa-0 ma-0' : 'tracking-history-table-light pa-0 ma-0'">
                <v-table :fixed-header="true" height="24" style="pointer-events: none;">
                  <thead>
                    <tr>
                      <th class="text-left" style="height: 24px;">
                        Start
                      </th>
                      <th class="text-left" style="height: 24px;">
                        Dwell / Transit
                      </th>
                      <th class="text-left" style="height: 24px;">
                        End
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Dummy Row for approximate column spacing in table -->
                    <tr>
                      <td>
                        <strong>1 - Shipment Information Received</strong><br />
                        South Windsor, CT 06074<br />
                        July 5th 2022, 12:40 am
                      </td>
                      <td style="white-space: nowrap;">
                        <v-icon large color="green">
                          mdi-truck-delivery
                        </v-icon>
                        9 hours / 10.89 mph
                      </td>
                      <td>
                        <strong>99 - Package Arrived</strong><br />
                        South Windsor, CT 06074<br />
                        July 5th 2022, 12:40 am
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <v-table
                  :style="{ 'overflow-y': 'scroll', height: 'calc(100% - 24px)', position: 'absolute', top: 24, left: 0, width: '100%' }">
                  <tbody>
                    <tr v-for="(leg, legIdx) in trackingInfo.legs" :key="legIdx" @click="tableLegClicked(legIdx)"
                      :class="legIdx === selectedLegIndex ? 'selected-history-row' : 'history-row'">
                      <td>
                        <strong>{{ leg.start.origIndex + 1 }} - {{ prettyStringForEnum(leg.start.substatus) }}
                          <v-tooltip activator="parent" location="end center" origin="start center">{{
                            statusDetailFromLeg(leg.start)
                          }}</v-tooltip>
                        </strong><br />
                        {{ leg.start.city }}{{ leg.start.city ? ', ' : '' }}{{ leg.start.state }}{{ leg.start.state ? ' ' : '' }}{{
                          leg.start.zip }}<br />
                        {{ prettyDateTime(leg.start.date) }}
                      </td>
                      <td v-if="leg.end">
                        <div class="d-flex justify-center align-center">
                          <v-icon v-if="!leg.dwell && legIsFlight(leg)" large color="green">
                            mdi-airplane
                          </v-icon>
                          <img v-if="!leg.dwell && !legIsFlight(leg)" style="width: 20px; height: 20px;"
                            :src="require('@/assets/markers/truck-delivery.png')">
                          <img v-if="leg.dwell" style="width: 20px; height: 20px;"
                            :src="isDarkTheme ? require('@/assets/markers/timer-marker-yellow-dark.png') : require('@/assets/markers/timer-marker-yellow-light.png')">
                          {{ legMetaContent(leg) }}
                        </div>
                      </td>
                      <td v-else>
                        <div class="d-flex justify-center align-center">
                          <img style="width: 20px; height: 20px;"
                            :src="isDarkTheme ? require('@/assets/markers/timer-marker-yellow-dark.png') : require('@/assets/markers/timer-marker-yellow-light.png')">
                          {{ legMetaContent(leg) }}
                        </div>
                      </td>
                      <td v-if="leg.end">
                        <strong>{{ leg.end.origIndex + 1 }} - {{ prettyStringForEnum(leg.end.substatus) }}
                          <v-tooltip activator="parent" location="start top" origin="center end">{{ statusDetailFromLeg(leg.end) }}
                          </v-tooltip>
                        </strong><br />
                        {{ leg.end.city }}{{ leg.end.city ? ', ' : '' }}{{ leg.end.state }}{{ leg.end.state ? ' ' : '' }}{{
                          leg.end.zip }}<br />
                        {{ prettyDateTime(leg.end.date) }}
                      </td>
                      <td v-else style="width: 40%;"> <!-- HACK!! -->
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
            </pane>
          </Splitpanes>
        </v-col>
      </v-row>
    </template>
    <template v-if="showWaitingScreen">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <div class="d-flex justify-center">
            <v-btn size="small" @click="checkTracker">Retry</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <!-- Show the waiting puppy meme -->
          <MemeContainer
            :height="(smAndUp ? (0.81 * (height - 135)) : (0.78 * (height - 135)))"
            :imagePath="require('@/assets/puppy_waiting.webp')"
            caption="Whoops. Rate-Limited. Try again in a minute."
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="!showTimeline && !showWaitingScreen && !showProgressBar">
      <v-row justify="center">
        <v-col cols="12">
          <!-- Show random waiting meme -->
          <MemeContainer
            :height="(smAndUp ? (0.93 * (height - 135)) : (0.83 * (height - 135)))"
            :imagePath="randomMemePath()"/>
        </v-col>
      </v-row>

    </template>
  </v-container>
</template>

<script>
import { computed, inject, reactive, ref, watch } from 'vue'
import {
  fetchTrackingInfo,
  prettyStringForEnum,
  trackerIsValid,
  courierForTracker,
  isDatePast,
  prettyETAStringFromStats,
  statusDetailFromLeg
} from '../utils/trackingUtils.js'
import { computeGreatCircleDistance, averageSpeed } from '../utils/math.js'
import moment from 'moment'
import { GoogleMap, Marker, Polyline } from 'vue3-google-map'
import MemeContainer from './MemeContainer.vue'
import { useAuth0 } from '@auth0/auth0-vue'
import TrackerSaveDialog from './TrackerSaveDialog.vue'
import { useDisplay } from 'vuetify'
import deepEqual from 'deep-equal'
import { Splitpanes, Pane } from 'splitpanes'
import { debounce } from 'lodash'
import 'splitpanes/dist/splitpanes.css'

export default {
    components: { GoogleMap, Marker, Polyline, MemeContainer, TrackerSaveDialog, Splitpanes, Pane },
    props: {
        // Pre-populate the tracker number search
        height: {
            type: Number,
            required: false,
            default: null
        },
        landscapeMode: {
          type: Boolean,
          required: false,
        },
        landscapeTrackingHistoryMaxWidth: {
          type: Number,
          required: false,
          default: 650
        },
        landscapeTrackingHistoryMinWidth: {
          type: Number,
          required: false,
          default: 475
        },
        tracker: {
            type: String,
            required: false,
            default: null
        },
        savedTrackers: {
            type: Array,
            required: false,
            default: () => null
        },
        isDarkTheme: {
          type: Boolean,
          required: true
        }
    },
    emits: ['saved-tracker-updated', 'tracker-input-updated', 'valid-tracker-input-updated'],
    setup(props, context) {
        // Logger
        const logger = inject('vuejs3-logger')

        // Authenticated Users
        const { isAuthenticated, user } = useAuth0()

        const savedDescriptionOfTracker = (tracker) => {
          let userDesc = null
          if (tracker && props.savedTrackers) {
            props.savedTrackers.forEach(value => {
              if (value?.tracker === tracker) {
                userDesc = value.description
              }
            })
          }
          return userDesc
        }

        // Main Component Refs
        const trackingNumber = ref('')
        const trackingNumberHint = ref('')
        const courierUnknown = '----'
        const courier = ref(courierUnknown)
        const trackingInfo = reactive({
          hops: [],
          legs: [],
          stats: {}
        })

        // UI state refs
        const { smAndUp, width, height } = useDisplay()
        const showTimeline = ref(false)
        const showProgressBar = ref(false)
        const showWaitingScreen = ref(false)

        // Tracking Map refs
        const googleAPIKey = process.env.VUE_APP_GOOGLE_API_KEY
        const trackingMap = ref(null)
        const trackingMapZoom = ref(8)
        const trackingMapOptions = reactive({
          mapType: 'terrain',
          mapTypeOptions: {
            mapTypeIds: ['roadmap', 'hybrid', 'terrain'],
            style: 'DROPDOWN_MENU',
            position: 3
          }
        })
        const mapDarkTheme = reactive({
          styles: [ // Dark Theme from Google API docs: https://developers.google.com/maps/documentation/javascript/examples/style-array
            { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
            { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
            { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [{ color: "#263c3f" }],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [{ color: "#6b9a76" }],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [{ color: "#38414e" }],
            },
            {
              featureType: "road",
              elementType: "geometry.stroke",
              stylers: [{ color: "#212a37" }],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [{ color: "#9ca5b3" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [{ color: "#746855" }],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [{ color: "#1f2835" }],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [{ color: "#f3d19c" }],
            },
            {
              featureType: "transit",
              elementType: "geometry",
              stylers: [{ color: "#2f3948" }],
            },
            {
              featureType: "transit.station",
              elementType: "labels.text.fill",
              stylers: [{ color: "#d59563" }],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [{ color: "#17263c" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [{ color: "#515c6d" }],
            },
            {
              featureType: "water",
              elementType: "labels.text.stroke",
              stylers: [{ color: "#17263c" }],
            },
          ]
        })

        // Query Functions
        const setTrackingHint = (stats) => {
          const savedDescRaw = savedDescriptionOfTracker(trackingNumber.value)
          const savedDesc = savedDescRaw ? `Saved As: '${savedDescRaw}', ` : ''
          const carrierDesc = `Carrier: ${prettyStringForEnum(courier.value)}`
          const serviceDesc = stats.service ? `, Service: ${stats.service}` : ''
          const statusDesc = `, Status: ${prettyStringForEnum(stats.status)}`
          const etaDesc = prettyETAStringFromStats(stats) ? `, ${prettyETAStringFromStats(stats)}` : ''

          // Set the hint string (below the main search field)
          trackingNumberHint.value = `${savedDesc}${carrierDesc}${serviceDesc}${statusDesc}${etaDesc}`
        }

        const executeTracking = async () => {
          if (courier.value != courierUnknown) {
            showProgressBar.value = true
            const tracker = trackingNumber.value
            logger.info(`Will now track the package with tracking number '${tracker}'...`)
            const res = await fetchTrackingInfo(tracker, courier.value, user.value?.sub)
            showProgressBar.value = false
            showWaitingScreen.value = false
            resetHistorySelection()
            // Check for rate limiting or other errors
            if(res?.status) {
              if (res.status === 429) {
                showWaitingScreen.value = true
              }
            } else {
              const { hops, legs, stats } = res
              logger.info(`Tracking Info Found: ${JSON.stringify({ hops, legs, stats }, null, 2)}`)
              trackingInfo.hops = hops
              trackingInfo.stats = stats
              trackingInfo.legs = legs
              if (trackingInfo.stats.status !== 'NOT FOUND') {
                showTimeline.value = true
              }
              setTrackingHint(trackingInfo.stats)
            }
          }
        }

        const checkTracker = () => {
          const tracker = trackingNumber.value
          logger.info(`Checking tracker '${tracker}' for validity...`)
          if (trackerIsValid(tracker)) {
            const trackerCarrier = courierForTracker(tracker)
            trackingNumberHint.value = `Carrier: ${trackerCarrier.toUpperCase()}`
            courier.value = trackerCarrier
            showTimeline.value = false
            // Signal that (valid) tracker input was entered
            context.emit('valid-tracker-input-updated', tracker)
            executeTracking()
          } else {
            if (tracker !== "") {
              trackingNumberHint.value = `Didn't recognize this tracking number format. 😖`
            } else {
              trackingNumberHint.value = ''
            }
            courier.value = courierUnknown
            showTimeline.value = false
            showWaitingScreen.value = false
          }
          // Signal that tracker input was modified
          context.emit('tracker-input-updated', tracker)
        }

        const getMarkerIcon = (hop, index, lastIndex) => {
          // Default location marker
          const ico = {
            url: props.isDarkTheme ? require('@/assets/markers/circle-small-dark.png') : require('@/assets/markers/circle-small-light.png'),
            anchor: { x: 24, y: 24 }
          }
          // Last hop gets icon based on status
          if (index === lastIndex) {
            // Set the URL
            switch (hop.substatus) {
              case 'delivered':
                ico.url = props.isDarkTheme ? require('@/assets/markers/flag-checkered-dark.png') : require('@/assets/markers/flag-checkered-light.png')
                ico.anchor = { x: 0, y: 48 }
                break
              case 'package_departed':
              case 'out_for_delivery':
              case 'package_forwarded':
                ico.url = require('@/assets/markers/truck-delivery.png')
                break
              case 'package_held':
              case 'notice_left':
              case 'pickup_available':
                ico.url = props.isDarkTheme ? require('@/assets/markers/office-building-marker-dark.png') : require('@/assets/markers/office-building-marker-light.png')
                ico.anchor = { x: 32, y: 48 }
                break
              case 'package_arrived':
              case 'package_accepted':
              case 'information_received':
              case 'package_processing':
              case 'package_processed':
              case 'delivery_scheduled':
              case 'other':
                ico.url = props.isDarkTheme ? require('@/assets/markers/timer-marker-yellow-dark.png') : require('@/assets/markers/timer-marker-yellow-light.png')
                ico.anchor = { x: 40, y: 44 }
                break
              case 'delayed':
              case 'return_to_sender':
              case 'address_issue':
                ico.url = require('@/assets/markers/timer-marker-outline-red.png')
                ico.anchor = { x: 40, y: 44 }
                break
              default:
                break
            }
          }
          return ico
        }

        const getMarkerOptions = (hop, index, lastIndex) => {
          return {
            position: hop.geo,
            clickable: false,
            draggable: false,
            zIndex: index,
            icon: getMarkerIcon(hop, index, lastIndex),
            animation: index === lastIndex ? trackingMap.value?.api?.Animation.BOUNCE ?? null : null
          }
        } 

        const legDistance = (leg) => {
          return leg.dwell ? 0 :
            computeGreatCircleDistance({
              lat1: leg.start.geo.lat,
              long1: leg.start.geo.lng,
              lat2: leg.end.geo.lat,
              long2: leg.end.geo.lng
            })
        }

        const legAverageSpeed = (leg) => {
            // Get hop-to-hop distance
            const dist = legDistance(leg)
            // Compute average speed
            const avgSpeed = averageSpeed(dist, leg.elapsed)
            return avgSpeed
        }

        const legIsFlight = (leg) => {
          // Use distance and average speed to 'guess' if leg is likely an airplane flight
          const legDist = legDistance(leg)
          const legSpeed = legAverageSpeed(leg)
          return legSpeed > 100 && legDist > 100
        }

        const legMetaContent = (leg) => {
          let metaContent = ''
          // Compute transit or dwell details description for the leg
          if (leg.dwell) {
            metaContent = `${moment.duration(leg.elapsed).humanize()}`
          } else if (leg.end === null) {
            // Assume dwell from first hop to 'now'
            metaContent = `${moment.duration(moment(new Date()).diff(moment(leg.start.date))).humanize()}`
          } else {
            const speed = legAverageSpeed(leg)
            metaContent = `${moment.duration(leg.elapsed).humanize()} / ${speed.toPrecision(4)} mph`
          }
          
          return metaContent
        }

        // Prop Handling
        watch(() => props.tracker, (curVal) => {
          if (curVal) {
            // Is this a new value?
            if (curVal !== trackingNumber.value) {
              // Set the tracking number
              trackingNumber.value = curVal
              // Kick-off query
              checkTracker()
            }
          }
        }, { immediate: true })

        watch(() => props.savedTrackers, (curVal, oldVal) => {
            // Refresh query + hint whenever the saved trackers change
            if (!deepEqual(curVal, oldVal)) {
              checkTracker()
            }
        })

        // Tracking History Refs
        const selectedLegIndex = ref(-1)

        // Tracking History Functions
        const resetHistorySelection = () => {
          selectedLegIndex.value = -1
        }
        const tableLegClicked = (legIndex) => {
          logger.debug(`User clicked leg at index: ${JSON.stringify(legIndex)}`)
          if (selectedLegIndex.value === legIndex) {
            selectedLegIndex.value = -1 // toggle selection off
            fitMapToTrackingPath() // reset map to entire tracking path
          } else {
            selectedLegIndex.value = legIndex // toggle selection on
            fitMapToLeg(trackingInfo.legs[legIndex])
          }
          reApplyAllMarkerAnimations() // HACK: works around a Google Maps API bug by re-applying animation prop for all markers
        }
        
        const reFitMapToSelection = debounce(() => {
          logger.debug(`Refitting Map to selection: ${selectedLegIndex.value}`)
          if (selectedLegIndex.value === -1) {
            fitMapToTrackingPath() // reset map to entire tracking path
          } else {
            fitMapToLeg(trackingInfo.legs[selectedLegIndex.value])
          }
          reApplyAllMarkerAnimations() // HACK: works around a Google Maps API bug by re-applying animation prop for all markers
        }, 500)

        // Tracking Map Functions
        const legHops = () => {
          // Create a list of (unique) hops included in the transit 'legs'
          const legHopIndicies = new Set()
          const lHops = []
          for (const leg of trackingInfo.legs) {
            legHopIndicies.add(leg.start.origIndex)
            if (leg.end) {
              legHopIndicies.add(leg.end.origIndex)
            }
          }
          for (const legHopIdx of legHopIndicies) {
            lHops.push({ ...trackingInfo.hops[legHopIdx], origIndex: legHopIdx })
          }
          return lHops
        }

        const trackingPath = () => {
          return {
            path: legHops().map(hop => hop.geo),
            geodesic: true,
            strokeColor: "#40A43E", // Green color to match timeline dots
            strokeOpacity: 1.0,
            strokeWeight: 5,
          }
        }

        const fitMapToTrackingPath = () => {
            if (trackingMap.value?.api) {
              // Setup bounds instance
              var latlngbounds = new trackingMap.value.api.LatLngBounds()
              // Add points for each marker
              legHops().forEach(hop => latlngbounds.extend(new trackingMap.value.api.LatLng(hop.geo.lat, hop.geo.lng)))
              // Set Bounds
              trackingMap.value.map.fitBounds(latlngbounds)
            }
        }

        const fitMapToLeg = (leg) => {
            if (trackingMap.value?.api) {
              // Setup bounds instance
              var latlngbounds = new trackingMap.value.api.LatLngBounds()
              // Extend bound with start + stop hops from selected leg
              latlngbounds.extend(new trackingMap.value.api.LatLng(leg.start.geo.lat, leg.start.geo.lng))
              if (leg.end) {
                latlngbounds.extend(new trackingMap.value.api.LatLng(leg.end.geo.lat, leg.end.geo.lng))
              }
              // Set Bounds to the map
              trackingMap.value.map.fitBounds(latlngbounds)
            }
        }

        // Tracking Map Markers
        const trackingMapMarkers = ref([])

        const reApplyAllMarkerAnimations = () => {
          trackingMapMarkers.value?.forEach( (markerRef, index) => {
            const anim = markerRef.marker.getAnimation()
            logger.debug(`Marker (${index}) has animation: ${anim}`)
            markerRef.marker.setAnimation(null)
            markerRef.marker.setAnimation(anim)
          })
        }

        // Fit the map bounds when the map + data are ready
        watch(() => trackingMap.value?.ready, (ready) => {
          if (!ready) return
          fitMapToTrackingPath()
        })

        // Watch Zoom level / Enforce zoom bounds
        const trackingMapZoomChanged = () => {
          const curZoom = trackingMap.value?.map.getZoom() ?? 0
          const maxZoom = 13
          const minZoom = 2

          logger.debug(`Tracking map zoom is now: ${curZoom}.`)
          // Enforce reasonable zoom bounds
          if (curZoom > maxZoom) {
            setTimeout(() => { 
              trackingMap.value?.map.setZoom(maxZoom)
              logger.debug(`Reset Tracking map zoom to max: ${maxZoom}.`)
            }, 30)
          } else if (curZoom < minZoom) {
            setTimeout(() => { 
              trackingMap.value?.map.setZoom(minZoom)
              logger.debug(`Reset Tracking map zoom to min: ${minZoom}.`)
            }, 30)
          }
        }

        const prettyDateTime = (rawDate) => {
          return moment(rawDate).format('MMMM Do YYYY, h:mm a')
        }

        // Front Page Memes
        const memes = [
          require('@/assets/memes/waiting/delivery-truck.webp'),
          require('@/assets/memes/waiting/mj-waiting.jpg'),
          require('@/assets/memes/waiting/waiting-for-my-package-like.jpg'),
          require('@/assets/memes/waiting/your-order-has-been-shipped.jpeg'),
          require('@/assets/memes/waiting/your-package-out-delivery.png'),
          require('@/assets/memes/waiting/your-package-will-arrive-between-3-and-6-pm.jpg'),
          require('@/assets/memes/waiting/when-your-package-says-out-for-delivery.jpg'),
          require('@/assets/memes/waiting/neil-primrose-travis.gif'),
          require('@/assets/memes/waiting/shipped-waiting.gif'),
          require('@/assets/memes/waiting/al-bundy.gif')
        ]

        const randomMemePath = () => {
          return memes[Math.floor((Math.random()*memes.length))]
        }

        // Save Tracker
        const saveTrackerDialog = ref(false)
        
        const canSaveCurrentTracker = computed(() => {
          let canSave = false
          // Necessary conditions:
          // The tracker exists
          // The user is authenticated
          // The user's savedTrackers prop is not null
          // The carrier is valid
          if (isAuthenticated.value &&
              trackingNumber.value &&
              courier.value !== courierUnknown
              && props.savedTrackers !== null) {
            // Check if tracker is already saved
            let found = false
            props.savedTrackers.forEach(value => {
              if (value?.tracker === trackingNumber.value) {
                found = true
              }
            })
            canSave = !found
          }
          return canSave
        })
        
        const saveTrackerInit = async () => {
          // Open the TrackerSaveDialog component
          saveTrackerDialog.value = true
        }

        const savedTrackerUpdated = (tracker) => {
          // Signal that saved tracker was modified
          context.emit('saved-tracker-updated', tracker)
        }
        
        // Landscape mode sizing / reactivity

        const trackerHistoryMapPanesResized = (sizes) => {
          logger.debug(`Tracking Map/Hisory Panes Resized: ${JSON.stringify(sizes)}`)
          reFitMapToSelection()
        }

        const trackingHistoryPaneSizePercent = ref(75)

        const trackingMapPaneSizePercent = computed(() => {
          // This value compliments the tracking history pane size
          return 100 - trackingHistoryPaneSizePercent.value
        })

        const trackingHistoryPaneMaxSizePercent = computed(() => {
          // This value is only manipulated from the default under 'landscape' mode
          if (props.landscapeMode) {
            // Enforce the max size (percent) based on the current screen width
            return (props.landscapeTrackingHistoryMaxWidth / width.value) * 100.00
          } 
          // Use the standard value
          return 75
        })

        const trackingHistoryPaneMinSizePercent = computed(() => {
          // This value is only manipulated under 'landscape' mode
          if (props.landscapeMode) {
            // Enforce the min size (percent) based on the current screen width
            return (props.landscapeTrackingHistoryMinWidth / width.value) * 100.00
          } 
          // For vertical mode, use the standard value
          return 25
        })

        // Screen size watcher
        watch([() => width.value, () => height.value], () => {
          if (props.landscapeMode) {
            // Check that the size of the tracking history pane remains in the specified bounds
            if (trackingHistoryPaneSizePercent.value < trackingHistoryPaneMinSizePercent.value) {
              trackingHistoryPaneSizePercent.value = trackingHistoryPaneMinSizePercent.value
            } else if (trackingHistoryPaneSizePercent.value > trackingHistoryPaneMaxSizePercent.value) {
              trackingHistoryPaneSizePercent.value = trackingHistoryPaneMaxSizePercent.value
            }
          }
          reFitMapToSelection()
        }, { immediate: true })

        return {
          trackingNumber,
          trackingNumberHint,
          courier,
          trackingInfo,
          checkTracker,
          showTimeline,
          showProgressBar,
          showWaitingScreen,
          legMetaContent,
          legIsFlight,
          prettyStringForEnum,
          prettyDateTime,
          isDatePast,
          googleAPIKey,
          tableLegClicked,
          selectedLegIndex,
          legHops,
          // Tracking Map Markers
          trackingMapMarkers,
          trackingPath,
          trackingMap,
          trackingMapZoom,
          trackingMapZoomChanged,
          randomMemePath,
          mapDarkTheme,
          trackingMapOptions,
          isAuthenticated,
          saveTrackerInit,
          saveTrackerDialog,
          canSaveCurrentTracker,
          smAndUp,
          getMarkerOptions,
          savedTrackerUpdated,
          // Tracking Info Utils
          statusDetailFromLeg,
          // Splitpanes functions / refs
          trackerHistoryMapPanesResized,
          trackingHistoryPaneSizePercent,
          trackingMapPaneSizePercent,
          trackingHistoryPaneMaxSizePercent,
          trackingHistoryPaneMinSizePercent
        }
    },
}
</script>

<style lang="scss" scoped>

.text-input-white {
  :deep(.v-field__input) {
    color: rgba(255, 255, 255, 0.6) !important;
  }
}

.selected-history-row, .selected-history-row:hover {
  background: rgba(var(--v-border-color), 0.25) !important;
}

.tracking-history-table-light {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(171, 171, 171, 1);
}

.tracking-history-table-dark {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(96, 96, 96, 1)
}
.splitpanes {background-color: transparent;}

:deep(.splitpanes__splitter:before) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.4s;
  background-color: rgba(79, 76, 76, 0.784);
  opacity: 0;
  z-index: 1;
}
:deep(.splitpanes--horizontal > .splitpanes__splitter) {
  position: relative;
  background-color: transparent;
  min-height: 6px;
  cursor:row-resize;
}
:deep(.splitpanes--vertical > .splitpanes__splitter) {
  position: relative;
  background-color: transparent;
  min-width: 6px;
  cursor:col-resize;
}
:deep(.splitpanes--vertical > .splitpanes__splitter:before) {
  left: -30px;
  right: -30px;
  height: 100%;
}
:deep(.splitpanes--horizontal > .splitpanes__splitter:before) {
  top: -30px;
  bottom: -30px;
  width: 100%;
}
:deep(.splitpanes--vertical > .splitpanes__splitter:hover:before) {
    opacity: 1;
}
:deep(.splitpanes--horizontal > .splitpanes__splitter:hover:before) {
    opacity: 1;
    z-index: 1000;
}
</style>